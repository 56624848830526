import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Form } from "../style/globalStyles"
import Switch from "react-switch"
import Moment from "moment"
import {
  enableOrDisableHealthCareProfessional,
  approveOrDissapproveHealthCareProfessional,
} from "../state"
export const SingleHealthCareProfessional = ({
  healthcareprofessionalData = {},
}) => {
  healthcareprofessionalData === null &&
    typeof history !== "undefined" &&
    history.go(-1)
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]
  // console.log(healthcareprofessionalData.scheduleBean)
  var descDay
  // var scheduleLookup
  // var ScheduleLookup
  // useEffect(() => {
  //   if (healthcareprofessionalData.scheduleBean) {
  var scheduleLookup = days
    .map((day) => {
      // console.log(day)
      descDay = day + "ScheduleDesc"
      // console.log(healthcareprofessionalData.scheduleBean[descDay])
      return {
        day,
        scheduleArray:
          healthcareprofessionalData.scheduleBean &&
          healthcareprofessionalData.scheduleBean[descDay].filter(
            (lookUp) => lookUp !== null
          ),
      }
    })
    .map((Day) => {
      // console.log(Day.day)
      // console.log(Day.scheduleArray)
      return {
        day: Day.day,
        schTime:
          Day.scheduleArray &&
          Day.scheduleArray.map((schLookup) => {
            // console.log(schLookup.lookupDescription)
            return schLookup && schLookup.lookupDescription
          }),
      }
    })
  // console.log(scheduleLookup)
  var ScheduleLookup = scheduleLookup.map((lookup, index) => {
    // console.log(parseInt(lookup.schTime[0]))
    return (
      lookup.schTime &&
      lookup.schTime[0] !== null && (
        <div key={index}>
          <h5 style={{ padding: "1rem 0", color: "gray" }}>{lookup.day}</h5>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {lookup.schTime.length !== 0 ? (
              lookup.schTime
                .sort(function (a, b) {
                  return parseInt(a) - parseInt(b)
                })
                .map((sT, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        margin: "0.5rem 0.5rem 0.5rem 0",
                        lineHeight: "2rem",
                        fontSize: ".9rem",
                        borderRadius: "5%",
                        padding: ".1rem .7rem",
                        // color: "white",
                        backgroundColor:
                          parseInt(sT) <= 11 ? "lightsalmon" : "lightskyblue",
                      }}
                    >
                      {sT}
                    </p>
                  )
                })
            ) : (
              <p style={{ fontSize: "0.8rem" }}>Schedule not available</p>
            )}
          </div>
          <br />
        </div>
      )
    )
  })
  // }
  // }, [healthcareprofessionalData.scheduleBean])
  // scheduleLookup = days
  //   .map((day) => {
  //     // console.log(day)
  //     descDay = day + "ScheduleDesc"
  //     // console.log(healthcareprofessionalData.scheduleBean[descDay])
  //     return {
  //       day,
  //       scheduleArray:
  //         healthcareprofessionalData.scheduleBean[descDay] && healthcareprofessionalData.scheduleBean[descDay],
  //     }
  //   })
  //   .map((Day) => {
  //     // console.log(Day.day)
  //     // console.log(Day.scheduleArray)
  //     return {
  //       day: Day.day,
  //       schTime: Day.scheduleArray.map((schLookup) => {
  //         // console.log(schLookup.lookupDescription)
  //         return schLookup && schLookup.lookupDescription
  //       }),
  //     }
  //   })
  // console.log(scheduleLookup)
  // const ScheduleLookup = scheduleLookup.map((lookup) => {
  //   console.log(parseInt(lookup.schTime[0]))
  //   return (
  //     lookup.schTime[0] !== null && (
  //       <div>
  //         <h5 style={{ padding: "1rem 0", color: "gray" }}>{lookup.day}</h5>
  //         <div style={{ display: "flex", flexWrap: "wrap" }}>
  //           {lookup.schTime.map((sT) => {
  //             return (
  //               <p
  //                 style={{
  //                   margin: "0.5rem 0.5rem 0.5rem 0",
  //                   lineHeight: "2rem",
  //                   fontSize: ".9rem",
  //                   borderRadius: "5%",
  //                   padding: ".1rem .7rem",
  //                   // color: "white",
  //                   backgroundColor:
  //                     parseInt(sT) <= 12 ? "lightsalmon" : "lightskyblue",
  //                 }}
  //               >
  //                 {sT}
  //               </p>
  //             )
  //           })}
  //         </div>
  //         <br />
  //       </div>
  //     )
  //   )
  // })
  const [state, setState] = useState({
    professionalId: healthcareprofessionalData.professionalId,
    isActive: healthcareprofessionalData.isActive,
    isVerified: healthcareprofessionalData.isVerified,
  })
  const [enable, setEnable] = useState(false)
  const dispatch = useDispatch()
  // useEffect(() => {
  //   if (healthcareprofessionalData) {
  //     setState(healthcareprofessionalData)
  //   }

  //   console.log("useEffect state", state)
  // }, [healthcareprofessionalData])
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
  }
  const handleSubscribeChange = (value) => {
    setState({ ...state, isSubscription: value })
    setEnable(true)
  }

  const activeChangeSuccess = (value) => {
    setState({ ...state, isActive: value })
    console.log("HCP active now")
  }
  const handleActiveChange = (value) => {
    if (state.isVerified === false) {
      return alert("Please verify the doctor to activate")
    }
    const hACValue = {
      ...state,
      isActive: value ? 1 : 0,
    }
    dispatch(
      enableOrDisableHealthCareProfessional(hACValue, activeChangeSuccess)
    )
    // setEnable(true)
  }
  const verifiedChangeSuccess = (value) => {
    setState({ ...state, ...value })
  }
  const handleVerifiedChange = (value) => {
    // setState({ ...state, isActive: value })
    const hVCValue = {
      ...state,
      isVerified: value ? 1 : 0,
    }
    dispatch(
      approveOrDissapproveHealthCareProfessional(
        hVCValue,
        verifiedChangeSuccess
      )
    )
    // setState({ ...state, isVerified: value ? 1 : 0 })
    // setEnable(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setEnable(false)
    alert("Doctor approval status has been updated")
    navigate("/doctors")
  }

  return (
    <>
      <Form onSubmit={handleSubmit} style={{}}>
        <div style={{ paddingRight: "1rem" }}>
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              borderBottom: "1px solid lightgray",
            }}
          >
            <label htmlFor="firstname">
              First Name
              <p>{healthcareprofessionalData.firstName}</p>
              {/* <input
              id="firstname"
              name="firstName"
              type="text"
              placeholder={healthcareprofessionalData.firstName}
              onChange={handleChange}
            /> */}
            </label>
            <label>
              Last Name
              <p>{healthcareprofessionalData.lastName || "NA"}</p>
            </label>
            <label htmlFor="age">
              Age
              <p>{healthcareprofessionalData.age || "NA"}</p>
            </label>
            <label htmlFor="genderDesc">
              Gender
              <p>{healthcareprofessionalData.genderDesc || "NA"}</p>
            </label>
            <label htmlFor="mobileNo">
              Mobile Number
              <p>{healthcareprofessionalData.mobileNo || "NA"}</p>
            </label>
            <label htmlFor="email">
              Email
              <p>{healthcareprofessionalData.email || "NA"}</p>
            </label>
            <p />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid lightgray",

              width: "100%",
            }}
          >
            <label htmlFor="professionalId">
              Professional ID
              <p>{healthcareprofessionalData.professionalId || "NA"}</p>
            </label>
            <label htmlFor="experience">
              Experience
              <p>{healthcareprofessionalData.experience || 0}</p>
            </label>
            <label htmlFor="professionDesc">
              Profession
              <p>{healthcareprofessionalData.professionDesc || "NA"}</p>
            </label>
            <label htmlFor="">
              University
              <p>
                {healthcareprofessionalData.university
                  ? healthcareprofessionalData.university
                  : "NA"}
              </p>
            </label>
            {/* <label htmlFor="speciality">
              Speciality
              <p>{healthcareprofessionalData.speciality || "NA"}</p>
            </label>
            <label htmlFor="specialityDesc">
              Speciality Description
              <p>{healthcareprofessionalData.specialityDesc || "NA"}</p>
            </label> */}

            <label>
              Languages Spoken
              <p>
                {healthcareprofessionalData.languageLookupMap
                  ? healthcareprofessionalData.languageLookupMap
                      .filter((lookUp) => lookUp !== null)
                      .map((lookUp) => {
                        return lookUp.lookupDescription
                      })
                      .join(", ")
                  : "NA"}
              </p>
            </label>
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid lightgray",

              width: "100%",
            }}
          >
            <label htmlFor="addressLine1">
              Address Line 1
              <p>
                {healthcareprofessionalData.addressLine1
                  ? healthcareprofessionalData.addressLine1
                  : "NA"}
              </p>
            </label>
            <label htmlFor="addressLine2">
              Address Line 2
              <p>
                {healthcareprofessionalData.addressLine2
                  ? healthcareprofessionalData.addressLine2
                  : "NA"}
              </p>
            </label>
            <label htmlFor="city">
              City
              <p>
                {healthcareprofessionalData.city
                  ? healthcareprofessionalData.city
                  : "NA"}
              </p>
            </label>
            <label htmlFor="state">
              State
              <p>
                {healthcareprofessionalData.state
                  ? healthcareprofessionalData.state
                  : "NA"}
              </p>
            </label>
            <label htmlFor="pincode">
              Pincode
              <p>
                {healthcareprofessionalData.pinCode
                  ? healthcareprofessionalData.pinCode
                  : "NA"}
              </p>
            </label>
            <label htmlFor="country">
              Country
              <p>
                {healthcareprofessionalData.country
                  ? healthcareprofessionalData.country
                  : "NA"}
              </p>
            </label>
          </div>
          <div
            style={{ marginTop: "1rem", borderBottom: "1px solid lightgray" }}
          >
            <br />
            <h3 style={{ marginBottom: "1rem" }}>
              Availability of Slots{" "}
              <span style={{ fontWeight: "lighter", color: "gray" }}>
                (24 Hour)
              </span>
            </h3>
            {healthcareprofessionalData.scheduleBean ? (
              ScheduleLookup
            ) : (
              <h5>Schedule not available</h5>
            )}
          </div>
        </div>
        <div
          style={{
            // marginBottom: "2rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            height: "fit-content",
          }}
        >
          <label htmlFor="registrationCertificate">
            Regs. Certificate
            <br />
            <br />
            <a
              target="_blank"
              href={healthcareprofessionalData.registrationCertificate}
            >
              View
            </a>
          </label>
          <label htmlFor="educationalCertificate">
            Edu. Certificate
            <br />
            <br />
            <a
              target="_blank"
              href={healthcareprofessionalData.educationalCertificate}
            >
              View
            </a>
          </label>
          <label htmlFor="isActive">
            Active Status
            <br />
            <br />
            <Switch
              name="isActive"
              className="react-switch"
              onChange={handleActiveChange}
              checked={state.isActive}
            />
            {/* <p>{healthcareprofessionalData.isActive ? "Active   " : "Not Active    "}</p> */}
          </label>
          <label htmlFor="isVerified">
            Verified Status
            <br />
            <br />
            <Switch
              name="isVerified"
              className="react-switch"
              onChange={handleVerifiedChange}
              checked={state.isVerified}
            />
          </label>
          <label htmlFor="isSubscription">
            <br />
            Subscription Status
            <br />
            <p>
              {healthcareprofessionalData.isSubscription
                ? "Subscribed"
                : "No Subscription"}
            </p>
            {/* <Switch
            name="isSubscription"
            className="react-switch"
            onChange={handleSubscribeChange}
            checked={state.isSubscription}
          /> */}
          </label>
          <label htmlFor="mobileNo">
            Created
            <p>
              {healthcareprofessionalData.created &&
                Moment(healthcareprofessionalData.created).format(
                  "DD MMM YYYY"
                )}
            </p>
          </label>
          <label htmlFor="mobileNo">
            Last updated
            <p>
              {healthcareprofessionalData.updated &&
                Moment(healthcareprofessionalData.updated).format(
                  "DD MMM YYYY"
                )}
            </p>
          </label>
          <br />
          <button
            type="submit"
            aria-disabled={enable ? true : false}
            style={{
              borderRadius: "5px",
              backgroundColor: enable ? "green" : "lightgray",
              cursor: enable ? "pointer" : "default",
            }}
          >
            Save Changes
          </button>
        </div>
      </Form>
      {/* <div style={{ marginTop: "1rem" }}>
        <br />
        <h3 style={{ marginBottom: "1rem" }}>
          Availability of Slots{" "}
          <span style={{ fontWeight: "lighter", color: "gray" }}>
            (24 Hour)
          </span>
        </h3>
        {scheduleLookup && ScheduleLookup}
      </div> */}
    </>
  )
}
